import { Dictionary } from '@onaio/utils';
import { PostExportParams } from '../../../../../configs/component-types';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { POSTS_API, SOURCES_API } from '../../../../../configs/env';

export const startAsycPostExport = async ({
  postExportParams,
}: {
  postExportParams: PostExportParams;
}): Promise<Dictionary> => {
  const service = new AkukoAPIService(POSTS_API, `export`);
  const res = await service.create({ ...postExportParams });
  return res as Dictionary;
};

export const asycClearPostCache = async ({
  postId
}: {
  postId: string;
}): Promise<Dictionary> => {
  const service = new AkukoAPIService(SOURCES_API, `source/posts/sources/refresh/${postId}`);
  const res = await service.update({});
  return res as Dictionary;
};
