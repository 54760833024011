import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { setEnv } from './utils';

export const ENV = setEnv('REACT_APP_ENV', 'dev');
export const IDENTITY_API = setEnv('REACT_APP_IDENTITY_API', 'https://identity.api.akuko.io/v1/');
export const SOURCES_API = setEnv('REACT_APP_SOURCES_API', 'https://sources.api.akuko.io/v1/');
export const QUERY_API = setEnv('REACT_APP_QUERY_API', 'https://query.api.akuko.io');
export const SOURCES_WEB_SOCKET_URL = setEnv(
  'REACT_APP_SOURCES_WEB_SOCKET_URL',
  'https://sources.api.akuko.io'
);
export const TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL',
  'https://sources-refresh-worker.api.akuko.io'
);
export const TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL',
  'https://sources-worker.api.akuko.io'
);
export const TEMPORAL_POST_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_POST_WORKER_WEB_SOCKET_URL',
  'https://posts-worker.api.akuko.io'
);
export const POSTS_API = setEnv('REACT_APP_POSTS_API', 'https://posts.api.akuko.io/v1/');
export const UPLOADS_API = setEnv('REACT_APP_UPLOADS_API', 'https://uploads.api.akuko.io/v1/');
export const ADMIN_API = setEnv('REACT_APP_ADMIN_API', 'https://admin.akuko.io/items/');
export const PM_TILES_CDN_URL = setEnv(
  'REACT_APP_PM_TILES_CDN_URL',
  'https://d3e0zu94abde6b.cloudfront.net/'
);
export const NOTIFICATIONS_API = setEnv(
  'REACT_APP_NOTIFICATIONS_API',
  'https://notifications.api.akuko.io/v1/'
);
export const ASSETS_BUCKET_NAME = setEnv('REACT_APP_ASSETS_BUCKET_NAME', 'assets.akuko.io');
export const POSTS_WEB_SOCKET_URL = setEnv(
  'REACT_APP_POSTS_WEB_SOCKET_URL',
  'https://posts.api.akuko.io'
);
export const REFRESHABLE_DATABASE_SOURCES_TYPES = setEnv(
  'REACT_APP_REFRESHABLE_DATABASE_SOURCES_TYPES',
  'postgres, msql, clickhouse'
);
export const AKUKO_APP_DOMAINS = setEnv('REACT_APP_AKUKO_APP_DOMAINS', 'akuko.io, akuko.co');
export const AKUKO_APP_SITE_UNDER_MAINTENANCE = setEnv(
  'REACT_APP_AKUKO_APP_SITE_UNDER_MAINTENANCE',
  'false'
);
/* @ts-ignore */
export const AKUKO_APP_RETRY_COUNT = Number(setEnv('REACT_APP_RETRY_COUNT', 15)) as number;
/* @ts-ignore */
export const AKUKO_APP_RETRY_INTERVAL = Number(setEnv('REACT_APP_RETRY_INTERVAL', 2000)) as number;
export const CUBE_SECRET = setEnv('REACT_APP_CUBE_SECRET', '');
export const AKUKO_QUERY_JWT_HEADER_ALGO = setEnv('REACT_APP_AKUKO_QUERY_JWT_HEADER_ALGO', 'HS256');
export const AKUKO_QUERY_JWT_TOKEN_EXPIRATION_TIME = setEnv('REACT_APP_AKUKO_QUERY_JWT_TOKEN_EXPIRATION_TIME', '10m');
export const AKUKO_APP_OIDC_AUTHORITY = setEnv(
  'REACT_APP_OIDC_AUTHORITY',
  ''
);
export const AKUKO_APP_OIDC_CLIENT_ID = setEnv(
  'REACT_APP_OIDC_CLIENT_ID',
  ''
);
export const AKUKO_APP_OIDC_CLIENT_SECRET = setEnv(
  'REACT_APP_OIDC_CLIENT_SECRET',
  ''
);
export const AKUKO_APP_OIDC_REDIRECT_URI_POSTFIX = setEnv(
  'REACT_APP_AKUKO_APP_OIDC_REDIRECT_URI_POSTFIX',
  'dashboard'
);
export const AKUKO_APP_OIDC_POST_LOGOUT_REDIRECT_URI_POSTFIX = setEnv(
  'REACT_APP_AKUKO_APP_OIDC_POST_LOGOUT_REDIRECT_URI_POSTFIX',
  'feed'
);
export const AKUKO_APP_HTTP_PROTOCOL = setEnv(
  'REACT_APP_AKUKO_APP_HTTP_PROTOCOL',
  'https'
);
export const AKUKO_APP_OIDC_MONITOR_SESSION = Boolean(setEnv(
  'REACT_APP_OIDC_MONITOR_SESSION',
  'true'
));
export const AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME = setEnv(
  'REACT_APP_AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME',
  'X-CubeJS-Token'
);
export const AKUKO_HOST_URL = setEnv(
  'REACT_APP_AKUKO_HOST_URL',
  `${AKUKO_APP_HTTP_PROTOCOL}://${window?.location?.host}`
);

export const userManager = new UserManager({
  authority: AKUKO_APP_OIDC_AUTHORITY,
  client_id: AKUKO_APP_OIDC_CLIENT_ID,
  client_secret: AKUKO_APP_OIDC_CLIENT_SECRET,
  redirect_uri: window?.location?.href,
  post_logout_redirect_uri: `${AKUKO_HOST_URL}/${AKUKO_APP_OIDC_POST_LOGOUT_REDIRECT_URI_POSTFIX}`,
  monitorSession: AKUKO_APP_OIDC_MONITOR_SESSION,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
});

export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};


export const AKUKO_APP_OIDC_CLIENT_REDIRECT_QUERY_PARAMS = setEnv(
  'REACT_OIDC_CLIENT_REDIRECT_QUERY_PARAMS', 
  'state,session_state,iss,code');