/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** Returns a value in window._env_ or process.env or the defaultValue passed in.
 *
 * @param name - The configurable name that is a property of the environment/configuration.
 * @param defaultValue - The default value to return the value is not defined in process.env and window._env_.
 */
export const setEnv = (key: string, defaultValue: string) => {
  return process.env[key] || defaultValue;
};

/**
 * Fetches fresh PMTiles before Mapbox requests them, bypassing browser disk cache.
 *
 * This function ensures that tiles are served fresh by making pre-fetch requests
 * with `cache: "reload"`, forcing the browser to bypass its disk cache.
 *
 * It dynamically fetches tiles based on the dataset's `minzoom` and `maxzoom` levels.
 * If the dataset only has `maxzoom: 0`, it fetches only `0/0/0.mvt`.
 * Otherwise, it fetches representative tiles from all zoom levels.
 *
 * @param {string} tileBaseUrl - Base URL for the PMTiles source (without `{z}/{x}/{y}.mvt`).
 * @param {number} minZoom - Minimum zoom level of the dataset.
 * @param {number} maxZoom - Maximum zoom level of the dataset.
 * @returns {Promise<void>} Resolves when all fresh tiles have been fetched.
 */
export const fetchFreshTiles = async (tileBaseUrl: string, minZoom: number, maxZoom: number): Promise<void> => {
  // Generate zoom levels from minZoom to maxZoom
  const zoomLevels = Array.from({ length: maxZoom - minZoom + 1 }, (_, z) => z + minZoom);
  const sampleTiles = zoomLevels.map((z) => ({ z, x: 0, y: 0 })); // Fetch only the available zoom levels

  try {
    await Promise.all(
      sampleTiles.map(async ({ z, x, y }) => {
        const tileUrl = `${tileBaseUrl}/${z}/${x}/${y}.mvt`;
        // eslint-disable-next-line no-console
        console.log(`📡 Fetching fresh tile: ${tileUrl}`);
        const response = await fetch(tileUrl, {
          method: "GET",
          cache: "reload", // Forces the browser to bypass disk cache
          mode: "cors",
        });

        if (!response.ok) {
          // eslint-disable-next-line no-console
          console.warn(`⚠️ Tile fetch failed: ${tileUrl} (Status: ${response.status})`);
        }
      })
    );

    // eslint-disable-next-line no-console
    console.log("✅ Fresh tiles fetched.");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("❌ Error fetching fresh tiles:", error);
  }
};
