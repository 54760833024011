import React, {useState, useEffect} from 'react';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {EditPostButton} from '../EditPostButton';
import {Dictionary} from '@onaio/utils/dist/types/types';
import {Dropdown, Modal, message, Popconfirm, Button} from 'antd';
import {SettingOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {ExportPost} from '../../Post/PostComponents/components/ExportPost';
import {AkukoAPIService} from '../../../services/serviceClass';
import {POSTS_API, SOURCES_API} from '../../../configs/env';
import {EmbedSettings} from '../../Post/Embed/EmbedSettings';
import {
  userHasViewAccess,
  userHasWriteAccess,
} from '../../Profile/components/ProfileMenu/helpers';
import {ERROR_GENERIC} from '../../../configs/constants';
import {UserDropdown} from '../UserDropdown';
import mixpanel from 'mixpanel-browser';
import {actionPostPublicEdit, actionPostDelete} from '../../Post/actions';
import {PostSettings} from '../PostSettings';
import {ScheduledSend} from '../ScheduledSend';
import './style.css';
import { ClearPostCache } from '../../Post/PostComponents/components/ClearPostCache';

const PostMenu = () => {
  const user = useSelector((store: Dictionary) => store.user);
  const post = useSelector((store: Dictionary) => store.post);
  const space = useSelector((store: Dictionary) => store.space);
  const history = useHistory<Dictionary>();
  const location = useLocation<Dictionary>();
  const params = useParams<Dictionary>();
  const dispatch = useDispatch();
  const [exportPost, setExportPost] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [postSettingsVisible, setPostSettingsVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [scheduledSendModalVisible, setScheduledSendModalVisible] =
    useState(false);
  const [clearCacheConfirmation, setClearCacheConfirmation] = useState(false);
  const [clearCacheProgressVisible, setClearCacheProgressVisible] = useState(false);
  const pathname: string = location.pathname;

  const items: any = [
    {
      label: (
        <a
          href='#dashboard'
          onClick={(e) => {
            e.preventDefault();
            if (post.space_id !== null && post.space_id !== undefined) {
              history.push(`/space/${post.space_id}/posts`);
            } else {
              history.push(`/dashboard`);
            }
          }}
        >
          Dashboard
        </a>
      ),
      key: 'dashboard',
    },
    {type: 'divider'},
    {
      label: (
        <a
          href='#new-post'
          style={
            pathname.includes('/post/') &&
            userHasWriteAccess(user, location, post, true)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={(e) => {
            e.preventDefault();
            let accountId, spaceId;
            if (location.pathname.includes('/space/') && params.id) {
              spaceId = params.id;
              accountId = space.account_id;
            } else if (
              location.pathname.includes('/post/') &&
              post.space_id &&
              userHasWriteAccess(user, location, post, true)
            ) {
              accountId = post.account_id;
              spaceId = post.space_id;
            } else {
              accountId = user.handle;
            }
            const service = new AkukoAPIService(POSTS_API, 'post');
            service
              .create({
                account_id: accountId,
                space_id: spaceId || null,
                author: user.email,
                edit: user.email,
                description: 'A description of my post...',
                title: 'My new post',
                components: [
                  {
                    type: 'title',
                  },
                ],
                data: {},
                sources: {},
              })
              .then((res) => {
                const newPost = res as Dictionary;
                mixpanel.track('Create Post', {post: newPost.uuid});
                history.push(`/post/${newPost.uuid}/edit`);
              })
              .catch(() => {
                message.error('error creating post');
              });
          }}
        >
          New Post
        </a>
      ),
      key: 'new-post',
    },
    {
      label: (
        <a
          href='#new-source'
          style={
            pathname.includes('/post/') &&
            userHasWriteAccess(user, location, post, true)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={(e) => {
            e.preventDefault();
            const service = new AkukoAPIService(SOURCES_API, 'source');
            service
              .create({
                name: 'My great source',
                account_id: space.id || user.handle,
                created_by: user.email,
                space_id: space.id || null,
                cube: 'MyGreatSource',
                config: {},
                data: [],
              })
              .then((res) => {
                const result = res as Dictionary;
                mixpanel.track('Create Source', {source: result.uuid});
                history.push(`/editor/data/${result.uuid}`);
              })
              .catch((error) => {
                message.error(error.message || ERROR_GENERIC);
              });
          }}
        >
          New Source
        </a>
      ),
      key: 'new-source',
    },
    {type: 'divider'},
    {
      label: (
        <a
          href='#sourcs'
          onClick={(e) => {
            e.preventDefault();
            if (post.space_id !== null && post.space_id !== undefined) {
              history.push(`/space/${post.space_id}/sources`);
            } else {
              history.push(`/dashboard/sources`);
            }
          }}
        >
          Sources
        </a>
      ),
      key: 'souces',
    },
    {type: 'divider'},
    {
      label: (
        <a
          href='#copy-post'
          className=''
          style={
            pathname.includes('/post/') &&
            userHasWriteAccess(user, location, post, true)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={(e) => {
            if (userHasWriteAccess(user, location, post)) {
              if (!pathname.includes('/post/')) return;
              const copyPost = Object.assign({}, post, {
                title: `${post.title} copy`,
                author: user.email,
              });
              const service = new AkukoAPIService(POSTS_API, 'post');
              service.create(copyPost).then((res) => {
                const copiedPost = res as Dictionary;
                message.success('Post copied');
                history.push(`/post/${copiedPost.uuid}/edit`);
              });
            }
          }}
        >
          Copy Post
        </a>
      ),

      key: 'copy-post',
    },
    {
      key: 'export-options',
      label: 'Export options',
      children: [
        {
          label: (
            <div
              style={
                post.space_id !== null &&
                post.space_id !== undefined &&
                userHasViewAccess(user, location, post)
                  ? {}
                  : {color: '#aaa', cursor: 'not-allowed'}
              }
              onClick={(e) => {
                if (
                  post.space_id !== null &&
                  post.space_id !== undefined &&
                  userHasViewAccess(user, location, post)
                ) {
                  e.preventDefault();
                  setExportPost(true);
                }
              }}
            >
              Export Post
            </div>
          ),
          key: 'export-post',
        },
        {
          key: 'scheduled-send',
          label: (
            <div
              style={
                post.space_id !== null &&
                post.space_id !== undefined &&
                userHasWriteAccess(user, location, post)
                  ? {}
                  : {color: '#aaa', cursor: 'not-allowed'}
              }
              onClick={() => {
                if (
                  post.space_id !== null &&
                  post.space_id !== undefined &&
                  userHasWriteAccess(user, location, post)
                ) {
                  setScheduledSendModalVisible(true);
                }
              }}
            >
              Scheduled send
            </div>
          ),
        },
      ],
    },
    {
      key: 'share',
      label: 'Share Post',
      children: [
        {
          key: 'publish',
          label: (
            <a
              style={
                pathname.includes('/post/') &&
                userHasWriteAccess(user, location, post)
                  ? {}
                  : {color: '#aaa', cursor: 'not-allowed'}
              }
              onClick={(e) => {
                if (!pathname.includes('/post/')) return;
                if (userHasWriteAccess(user, location, post)) {
                  const status = post.public ? false : true;
                  const updatePost = Object.assign({}, post, {
                    public: status,
                  });
                  const service = new AkukoAPIService(
                    POSTS_API,
                    `post/${post.uuid}`
                  );
                  service.update(updatePost).then((res) => {
                    const updatedPost = res as Dictionary;
                    updatedPost.public
                      ? message.success('Post published')
                      : message.success('Post unpublished');
                    dispatch(
                      actionPostPublicEdit({
                        value: updatedPost.public,
                        updated: updatedPost.updated,
                      })
                    );
                  });
                }
              }}
            >
              {post.public ? <>Unpublish Post</> : <>Publish Post</>}
            </a>
          ),
        },
        {
          label: (
            <a
              style={
                post.public && userHasWriteAccess(user, location, post)
                  ? {}
                  : {color: '#aaa', cursor: 'not-allowed'}
              }
              href='#embed'
              onClick={(e) => {
                e.preventDefault();
                if (!post.public) return;
                if (userHasWriteAccess(user, location, post)) {
                  setModalVisible(true);
                }
              }}
            >
              Embed Post
            </a>
          ),
          key: 'embed-post',
        },
      ],
    },
    {
      key: 'delete-post',
      label: (
        <div
          style={
            pathname.includes('/post/') &&
            userHasWriteAccess(user, location, post)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={() => {
            if (userHasWriteAccess(user, location, post)) {
              setDeleteModalVisible(true);
            }
          }}
        >
          Delete Post
        </div>
      ),
    },
    {
      key: 'refresh-post',
      label: (
        <div
          style={
            pathname.includes('/post/') &&
            userHasViewAccess(user, location, post)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={() => {
            if (userHasViewAccess(user, location, post)) {
              setClearCacheConfirmation(true);
            }
          }}
        >
          Refresh Post
        </div>
      ),
    },
    {type: 'divider'},
    {
      key: 'post-settings',
      label: (
        <div
          style={
            pathname.includes('/post') &&
            userHasWriteAccess(user, location, post, true)
              ? {}
              : {color: '#aaa', cursor: 'not-allowed'}
          }
          onClick={() => {
            if (
              pathname.includes('/post') &&
              userHasWriteAccess(user, location, post)
            ) {
              setPostSettingsVisible(true);
            }
          }}
        >
          <SettingOutlined /> Post Settings
        </div>
      ),
    },
    {type: 'divider'},
    {
      key: 'docs',
      label: (
        <a target='blank' href='https://docs.akuko.io'>
          Documentation
        </a>
      ),
    },
  ];

  return (
    <div>
      <Dropdown
        dropdownRender={(children) => (
          <div className='post-menu'>{children}</div>
        )}
        menu={{
          items,
        }}
      >
        <div className='global-nav-mark'>Akuko</div>
      </Dropdown>
      {!user.authenticated && (
        <p className='global-nav-prompt'>
          Akuko is a platform for telling stories with data
        </p>
      )}
      <EditPostButton />
      <UserDropdown />
      <EmbedSettings
        onCancel={() => {
          setModalVisible(false);
        }}
        showModal={modalVisible}
      />
      {post.space_id !== null &&
      post.space_id !== undefined &&
      userHasWriteAccess(user, location, post) ? (
        <ScheduledSend
          open={scheduledSendModalVisible}
          onCancel={() => {
            setScheduledSendModalVisible(false);
          }}
        />
      ) : null}
      {userHasViewAccess(user, location, post) && (
        <ClearPostCache 
          visible={clearCacheProgressVisible} 
          onClose={() => setClearCacheProgressVisible(false)} 
        />
      )}
      {userHasViewAccess(user, location, post) && (
        <Modal
          open={clearCacheConfirmation}
          title="Confirm Post Refresh"
          okText={'Yes'}
          onCancel={() => setClearCacheConfirmation(false)}
          onOk={() => {
            setClearCacheConfirmation(false);
            setClearCacheProgressVisible(true);
          }}
        >
          <p>Refreshing this post may take some time to reflect. Are you sure you want to continue?</p>
      </Modal>
      )}
      {userHasWriteAccess(user, location, post) ? (
        <ExportPost
          onCancel={() => {
            setExportPost(false);
          }}
          open={exportPost}
        />
      ) : null}
      <Modal
        open={deleteModalVisible}
        okText={'Yes, delete it.'}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => {
          const service = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
          service.delete().then(() => {
            message.success('Post Deleted');
            dispatch(
              actionPostDelete({
                uuid: post.uuid,
              })
            );
            history.push('/dashboard/posts');
            message.success('Post deleted');
          });
        }}
      >
        <b>Caution</b>
        <p>Are you sure you want to delete this Post?</p>
      </Modal>
      <PostSettings
        open={postSettingsVisible}
        onCancel={() => setPostSettingsVisible(false)}
      />
    </div>
  );
};

export {PostMenu};
