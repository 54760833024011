import React, { useEffect, useState } from 'react';
import { Modal, Progress, Typography, notification } from 'antd';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';

import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../../../configs/env';
import { asycClearPostCache } from '../helpers';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useSelector } from 'react-redux';
import { FORCE_TILE_REFRESH_KEY } from '../../../../../configs/constants';

const { Text } = Typography;

interface ClearCacheModalProps {
  visible: boolean;
  onClose: () => void;
}

const ClearPostCache: React.FC<ClearCacheModalProps> = ({ visible, onClose }) => {
  const post = useSelector((store: Dictionary) => store.post);
  const [jobUuid, setJobUuid] = useState('');
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<"active" | "success" | "exception">("active");

  useEffect(() => {
    if(post?.uuid && visible) {
      setStatus("active");
      setProgress(0);
      triggerClearPostCache(post?.uuid);
    }
  }, [post, visible]);

  const checkExportProgress = async (
    jobId: string,
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
  ): Promise<void> => {
    socket?.on(
      jobId,
      async (data: {
        progress: {
          stage: string;
          data: Dictionary[];
          errors: Dictionary[];
        };
      }) => {
        if (data?.progress?.stage !== 'DONE' && data?.progress?.errors.length === 0) {
          const percentage = data?.progress?.data[0]?.percentage;
          setProgress(percentage)
        }
        if (data?.progress?.stage === 'DONE') {
          const percentage = data?.progress?.data[0]?.percentage;
          setProgress(percentage);
          setStatus("success");
          setJobUuid("");
          socket?.disconnect();
          setTimeout(() => {
            onClose();
            notification.success({
              message: "Post Refreshed",
              description: "The post has been refreshed successfully.",
              duration: 4,
            });
          }, 500);

          setTimeout(() => {
            localStorage.setItem(FORCE_TILE_REFRESH_KEY, "true");
            window.location.reload();
          }, 1000);
        }
        if (data?.progress?.errors.length === 1) {
          setJobUuid("");
          setStatus("active");
          setProgress(0);
          notification.error({
            message: "Post Refresh Failed",
            description: data?.progress?.errors[0].message,
            duration: 6,
          });
          onClose();
          socket?.disconnect();
        }
      }
    );
  };

  const triggerClearPostCache = (postId: string): void => {
    const socket = io(TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL, {
      path: '/v1/socket.io',
      withCredentials: true,
    });
    socket.on('connect', async () => {
      if (jobUuid !== '') {
        await checkExportProgress(jobUuid, socket);
      } else {
        asycClearPostCache({
          postId,
        }).then(async (res) => {
          const data = res as Dictionary;
          setJobUuid(data.job_uuid);
          await checkExportProgress(data.job_uuid, socket);
        });
      }
      // eslint-disable-next-line no-console
      console.log(
        `client connected to websock server ${TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL}, socket id: ${socket.id}`
      );
    });
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log(`client disconnected from websock server ${TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL}`);
    });
  };

  return (
    <>
      <Modal
        title="Refreshing Post"
        open={visible}
        onCancel={() => onClose()}
        footer={null}
      >
        <Progress percent={progress} status={status} showInfo={false} />
        <Text type="secondary">Progress: {progress}%</Text>
      </Modal>
    </>
  );
};

export { ClearPostCache };
