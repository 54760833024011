// errors
export const ERROR_GENERIC = 'An error occurred';
export const FILE_EXCEEDS_10GB_LIMIT_ERROR = 'File must be smaller than 10GB.';
export const FILE_UPLOAD_ERROR_MESSAGE = 'Error processing upload';
export const FIELD_REQUIRED_MESSAGE = 'No field selected';
export const INVALID_SOURCE_NAME_ERROR =
  'Source names must begin with a letter and consist of letters and numbers only.';

// success messages
export const FILE_UPLOAD_MESSAGE = 'File uploaded';
export const SOURCE_SCHEMA_UPDATED_MESSAGE = 'Schema updated';
export const SOURCE_SCHEMA_CREATED_MESSAGE = 'Schema created';
export const CACHE_CLEARED_MESSAGE = 'Cache cleared';

// cards
export const CARD_DEFAULT_PAGINATION_SIZE = 10;
export const CARD_DEFAULT_NUMBER_PER_ROW = 3;
export const CARD_DEFAULT_QUERY_LIMIT = 50000;

// charts
export const CHART_DEFAULT_QUERY_LIMIT = 50000;

// table
export const TABLE_DEFAULT_QUERY_LIMIT = 20000;
export const TABLE_DEFAULT_PAGINATION_SIZE = 10;

// icon
export const ICON_SIZE_MIN = 20;
export const ICON_SIZE_MAX = 200;

// Default colors
export const COLORS = [
  '#fb5c50',
  '#b9a2ba',
  '#c84dfe',
  '#568a40',
  '#71fadf',
  '#f8e490',
  '#927ddd',
  '#762f93',
  '#910dbe',
  '#3a051f',
  '#7a44a1',
  '#4a1f4f',
  '#7a2c3a',
  '#a10548',
  '#59c31c',
  '#f2c270',
  '#7600fd',
  '#81aa53',
  '#d58713',
  '#61aa9e',
  '#86afb9',
  '#ea1fd1',
  '#90d0c0',
  '#790e4e',
  '#38fe38',
  '#947ae7',
  '#a93f10',
  '#106a4b',
  '#4c301e',
  '#216060',
  '#920f4b',
  '#879325',
  '#26e6ca',
  '#245a72',
  '#7c95a0',
  '#3f828b',
  '#8ef64a',
  '#b6124f',
  '#7bca0c',
  '#4b4120',
  '#2c4a76',
  '#52e05c',
  '#d71960',
  '#1271a6',
  '#3fce05',
  '#a0093e',
  '#8eb30d',
  '#c009a7',
  '#46ec23',
  '#ce9bc3',
  '#dfd2cf',
  '#d8dadc',
  '#dec502',
  '#ac0b76',
  '#18296e',
  '#d2c181',
  '#47424c',
  '#9b2447',
  '#4a36f0',
  '#444d17',
  '#b26719',
  '#973772',
  '#9139ba',
  '#79bc1a',
  '#9ec436',
  '#ea1247',
  '#228d00',
  '#25770f',
  '#10912e',
  '#f2fe1e',
  '#20bd3f',
  '#9773d0',
  '#f6070e',
  '#b7a361',
  '#857523',
  '#cf1b69',
  '#4f573f',
  '#89642d',
  '#9c30e8',
  '#ccf6f8',
  '#8bd463',
  '#c29876',
  '#7f959f',
  '#c5301c',
  '#e0e657',
  '#3a0e14',
  '#a406e4',
  '#12375f',
  '#fd3f0f',
  '#168987',
  '#642c32',
  '#5eae4b',
  '#ed427e',
  '#2ac037',
  '#8b74f8',
  '#86d5d9',
];

// button texts
export const UPLOAD_BUTTON_TEXT = 'Upload';
export const CLEAR_CACHE_BUTTON_TEXT = 'Clear cache';
export const REFRESH_BUTTON_TEXT = 'Refresh';
export const CLOSE_BUTTON_TEXT = 'Close';

// select options texts
export const CSV_OPTION_TEXT = 'CSV';
export const GEOJSON_OPTION_TEXT = 'GeoJSON';
export const POSTGRESQL_OPTION_TEXT = 'PostgreSQL';
export const GOOGLE_SHEETS_OPTION_TEXT = 'Google Sheets';
export const SELECT_DATA_SOURCE_TEXT = 'Select data source';

// default texts
export const DEFAULT_SOURCE_NAME = 'My great source';

export const FORCE_TILE_REFRESH_KEY = "forceTileRefresh";
